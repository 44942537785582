.users-container {
  //background-color: $g-white;
  padding-top: 85px;
  min-height: 100%;
  padding-left: 50px;
  padding-right: 50px;

  .users {
    &-listing {
      &-statistic {
        @include shdow;
        background-color: $white;
        border-radius: $radius;
        width: 100%;
        height: 123px;
        padding: 15px;
        align-items: center;
        &-section {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 35px;
          margin: 30px 0;
        }
        &-wraper {
          @include my_flex_space;
          border-bottom: 1px solid $primary-color;
          padding-bottom: 5px;
        }
        &-icon {
          height: 45px;
          width: 45px;
          border-radius: 100%;
          background-color: $primary2;
          @include my_flex_center;
          margin-right: 5px;

          path {
            color: $white;
          }
        }
        &-ctn {
          @include my_flex_center;
          flex-direction: column;
          align-items: flex-end;

          h6 {
            font-family: $lato-bold;
            //font-weight: 600;
          }
          div {
            font-family: $lato-regular;
            ///font-weight: 600;
          }
        }

        .see-link {
          @include my_flex_center;
          justify-content: flex-start;
          margin: 10px 0;
          * {
            color: $primary-color;
            font-size: 15px;
          }
        }
      }
      &-section {
        padding: 35px 15px;
        //@include shdow;
        background-color: $white;
        margin-bottom: 35px;
        border-radius: $radius;
      }
      &-table {
        table {
          width: 100%;
        }
        td,
        th {
          padding: 15px;
          border-bottom: 1px solid #0000000f;
        }
        th {
          font-weight:  normal !important;
          font-family: $lato-bold;
        }
      }
    }
  }
}

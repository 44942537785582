.dashboard-nav-container {
  background-color: $white;
  height: 80px;
  width: calc(100% - 300px);
  @include my_flex_center;
  justify-content: flex-start;
  padding: 0 25px;
  position: fixed;
  //@include shdow;
  box-shadow: -5px -5px 9px rgba(255,255,255,0.45), 5px 5px 9px rgba(94,104,121,0.3);
  z-index: 15;

  @media screen and (max-width: 1100px) {
    width: 100% !important;
  }

  .notif {
    // position: absolute;
    height: 40px;
    width: 40px;
    // transform: translate(-50%, -50%);
    @include my_flex_center;
    //top: 0;
    //right: 0px;

    path {
      color: $dark75;
    }
    svg {
      height: 20px;
      width: 20px;
    }

    span {
      height: 7px;
      width: 7px;
      background-color: $primary-color;
      border-radius: 100%;
      position: absolute;
      top: 13px;
      right: 22px;
    }
  }

  .dashboard {
    &-nav {
      @include my_flex_space;
      //padding: 0 25px;
      &-icon {
        path {
          color: $secondary-color;
        }
        span {
          padding: 5px;
        }
      }
    }
    &-user {
      @include my_flex_center;
      cursor: pointer;
      &-name {
        color: $secondary-color;
        font-family: $lato-bold;
        padding-right: 5px;
      }
    }
  }
}

.dashboard-sidenav-container {
  overflow-x: hidden;
  background-color: $secondary-color;
  color: $other;
  display: block;
  height: 100%;

  .logo {
    height: 35px;
    @include my_flex_center;
    margin-top: 15px;
    img {
      @include img_contain;
    }
  }

  .user {
    &-section {
      @include my_flex_center;
      flex-direction: column;
      padding: 15px;
      border-bottom: 1px solid $other-color;
    }
    &-img {
      height: 96px;
      width: 96px;
      overflow: hidden;
      img {
        border-radius: 100%;
        @include img_cover;
        transition: 0.5s;
      }

      &:hover {
        img {
          border-radius: 100%;
          transform: scale(1.5);
          @include img_cover;
        }
      }

      .camera {
        position: absolute;
        height: 40px;
        width: 40px;
        @include my_flex_center;
        bottom: 0;
        right: 0;
        background-color: rgba($black, 0.35);
        border-radius: 50%;
        cursor: pointer;

        path {
          color: $other;
        }
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
    &-name {
      color: $other;
      font-family: $lato-bold;
      font-size: 20px;
      padding: 15px 0 5px;
      text-align: center;
      line-height: 28px !important;
    }
    &-title {
      color: $ash1 /* #FCDF45 */;
    }
  }

  .nav-section {
    padding: 20px;
    ul {
      margin: 10px 0;
      padding: 0;
    }
    li {
      width: 100%;
      padding: 12px;
      @include my_flex_center;
      justify-content: flex-start;
      margin: 5px 0;
      //other-space: nowrap;

      path {
        color: $other;
      }
      span {
        color: $other;
        margin-left: 10px;
        //other-space: nowrap;
      }
    }
  }

  .nav-active {
    li {
      background-color: $primary-color;
      border-radius: $radius;
    }
  }
}

.offcanvas-body {
  padding: 0 !important;
  &::-webkit-scrollbar {
    display: none;
  }
}

.rs-sidebar {
  display: none !important;
}
@media screen and (max-width: 1100px) {
  .rs-sidebar {
    display: block !important;
  }
}

.offcanvas-header{
  background-color: $secondary-color;
  color: $other;
}

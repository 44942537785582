@mixin my_btn($bg: #334875, $col: #fff) {
  border-radius: 5px;
  text-align: center;
  padding: 10px 20px;
  border: none;
  background-color: $bg;
  color: $col;
  transition: all ease-in-out 0.4s;
  white-space: nowrap;

  a {
    color: $white;
    &:hover {
      color: $white;
    }
  }

  &:hover {
    background-color: $primary;
  }
}

@mixin my_flex_space {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin my_flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin shdow($a: 14px, $b: 16px) {
  box-shadow: 0 $a $b 0 rgba(0, 0, 0, 0.1);
  //box-shadow: 0px 4px 6px rgb(0 0 0 / 5%), 0px 2px 4px rgb(0 0 0 / 5%),
  // 0px 1px 2px rgb(0 0 0 / 10%);
}

@mixin img_cover {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

@mixin img_contain {
  width: 100%;
  object-fit: contain;
  height: 100%;
}

.error-container {
  //background: url("../../../public/assets/images/img-hero.jpeg") center/cover;
  height: 100vh;
  width: 100%;
  @include my-flex_center;
  overflow: hidden;

  .error {
    @include my-flex_center;
    font-size: 34px;
    color: $primary-color;
    font-family: $lato-bold;
    text-align: center;
    margin-top: 15px;
    padding-top: 20px;

    &-img {
      /* width: 250px;
        height: 250px;  */
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-wraper {
      @include my-flex_center;
      flex-direction: column;
      height: 100%;
    }
  }

  /*      &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba($color: $white, $alpha: 0.4);
  }  */

  .hero-text {
    text-align: center;
    padding-bottom: 15px;
    //font-size: 18px;
    font-family: $lato-light;
  }

  .name {
    color: $primary-color;
    font-size: 18px;
    font-family: $lato-regular;
  }

  .form {
    &-wraper {
      @include my-flex_center;
      height: 100%;
    }
    &-container {
      background-color: $white;
      width: 40%;
      @include shdow;
      padding: 35px 20px 50px;
      border-radius: $radius;
    }
  }
}

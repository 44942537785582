.dashboard-sidebar-container {
  width: $side-width;
  height: 100vh !important;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: $secondary-color;
  color: $other;
  display: block;

  @media screen and (max-width: 1100px) {
    display: none !important;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .logo {
    height: 35px;
    @include my_flex_center;
    margin-top: 15px;
    img {
      @include img_contain;
    }
  }

  .user {
    &-section {
      @include my_flex_center;
      flex-direction: column;
      padding: 15px;
      border-bottom: 1px solid $other;
    }
    &-img {
      height: 96px;
      width: 96px;
      overflow: hidden;
      img {
        border-radius: 100%;
        @include img_cover;
        transition: 0.5s;
      }

      &:hover {
        img {
          border-radius: 100%;
          transform: scale(1.5);
          @include img_cover;
        }
      }

      .camera {
        position: absolute;
        height: 40px;
        width: 40px;
        @include my_flex_center;
        bottom: 0;
        right: 0;
        background-color: rgba($black, 0.35);
        border-radius: 50%;
        cursor: pointer;

        path {
          color: $other;
        }
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
    &-name {
      color: $other;
      font-family: $lato-bold;
      font-size: 20px;
      padding: 15px 0 5px;
      text-align: center;
      line-height: 28px !important;
    }
    &-title {
      color: $ash1 /* #FCDF45 */;
    }
  }

  .nav-section {
    padding: 20px;
    ul {
      margin: 10px 0;
      padding: 0;
    }
    li {
      width: 100%;
      padding: 12px;
      @include my_flex_center;
      justify-content: flex-start;
      margin: 5px 0;
      //white-space: nowrap;

      path {
        color: $other;
      }
      span {
        color: $other;
        margin-left: 10px;
        //white-space: nowrap;
      }
    }
  }

  .nav-active {
    li {
      background-color: $primary-color;
      border-radius: $radius;
    }
  }
}

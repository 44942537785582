.login-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  background: url("../../../public/assets/images/img-hero.jpeg") center/cover;
  //-webkit-transform: matrix(-1,0,0,1,0,0);

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba($color: $primary-color, $alpha: 0.2);
  }

  .login {
    &-form {
      &-container {
        width: 50%;
        @include my_flex_center;
        height: 100%;
        @media  (max-width: 980px) {
          width: 88%;
        }
        @media  (max-width: 1380px) {
          width: 80%;
        }
        label {
          display: block;
          margin: 15px 0 5px 0;
          font-family: $lato-bold !important;
        }

        h5 {
          text-align: center;
        }

        .remember-forgot {
          @include my_flex_space;
          margin-bottom: 20px;
        }
        .remember-container {
          display: flex;
          width: 100%;

          label {
            padding: 0;
            margin: 0;
            margin-left: 15px;
          }
        }

        .password {
          border: 1px solid #ccc;
          display: flex;
          align-items: center;
          border-radius: $radius;
          width: 100%;
          margin-bottom: 20px;
          span {
            border-left: 1px solid #ccc;
            padding: 9px 0;
            cursor: pointer;
            background: $primary-color;
            width: 50px;
            display: flex;
            justify-content: center;

            svg {
              font-size: 18px;
            }
            path {
              color: $white !important;
            }

            &:hover {
              opacity: 0.8;
            }
          }
        }

        .create-account {
          text-align: center;
          margin: 15px 0;
        }
      }

      &-wraper {
        background-color: $white;
        width: 60%;
        @media  (max-width: 980px) {
          width: 100%;
        }
        padding: 50px 40px;
        @include shdow();
        border-radius: $radius;
      }
    }
  }
}

.profile-container {
  background-color: $g-white;
  padding-top: 85px;
  min-height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: $mb;

  .profile {
    &-wraper {
      padding-bottom: 25px;
      @include shdow;
      background-color: $white;
      margin-bottom: 35px;
      border-radius: $radius;
    }
    &-grid {
      &-section {
        display: grid;
        gap: 25px;
        grid-template-columns: repeat(3, 1fr);
        &-container {
          padding: $mb;
        }
      }
      &-item {
        &-title {
          font-family: $lato-bold;
          padding: 15px;
          padding-bottom: 10px;
          color: $primary-color;
        }
        &-wraper {
          background-color: $g-white;
          @include my_flex_space;
          justify-content: flex-start !important;
          min-width: 275px;
          padding: 15px;
          border-radius: $radius;
        }
        &-icon {
          padding-right: 10px;

          svg {
            height: 18px;
            width: 18px;
          }
          path {
            color: $ash1;
          }
        }
        &-content {
          color: $black;
          ///white-space: wrap;
        }
      }
    }
    &-address {
      &-container {
        padding: 0 $mb;
        .profile-grid {
          &-item {
            &-title {
              font-family: $lato-bold;
              padding: 15px;
              padding-bottom: 10px;
              color: $primary-color;
            }
            &-wraper {
              background-color: $g-white;
              @include my_flex_space;
              justify-content: flex-start !important;
              width: 100% !important;
              padding: 15px;
              border-radius: $radius;
            }
            &-icon {
              padding-right: 10px;

              svg {
                height: 18px;
                width: 18px;
              }
              path {
                color: $ash1;
              }
            }
            &-content {
              color: $black;
            }
          }
        }
      }
    }
    &-edit {
      @include my_flex_space;
      border: 1px solid $primary-color;
      width: max-content;
      padding: 8px 20px;
      border-radius: $radius;
      cursor: pointer;

      span {
        margin-right: 5px;
      }
    }
  }
}

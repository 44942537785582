.dashboard-container {
  background-color: $g-white;
  padding-top: 85px;
  min-height: 100%;
  padding-left: 20px;
  padding-right: 20px;

  .dashboard {
    &-statistic {
      @include shdow;
      background-color: $white;
      border-radius: $radius;
      //width: 225px;
      width: 100%;
      height: 123px;
      //margin:25px 15px 35px;
      padding: 15px;
      //@include my_flex_space;
      //display: flex;
      align-items: center;
      &-section {
        //display:flex;
        //@include my_flex_space;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 35px;
        margin: 30px 0;
        @media (max-width: 768px) {
          grid-template-columns: repeat(1, 1fr);
        }
        @media (min-width: 770px) and (max-width:1000px){
          grid-template-columns: repeat(2, 1fr);
        }
        //flex-wrap: wrap;
      }
      &-wraper {
        @include my_flex_space;
        border-bottom: 1px solid $primary-color;
        padding-bottom: 5px;
        flex-wrap: wrap;
      }
      &-icon {
        height: 45px;
        width: 45px;
        border-radius: 100%;
        background-color: $primary2; //rgba($primary2, 0.4);
        @include my_flex_center;
        margin-right: 5px;

        path {
          color: $white;
        }
      }
      &-ctn {
        @include my_flex_center;
        flex-direction: column;
        align-items: flex-end;

        h6 {
          font-family: $lato-bold;
          //font-weight: 600;
        }
        div {
          font-family: $lato-regular;
          ///font-weight: 600;
        }
      }

      .see-link {
        @include my_flex_center;
        justify-content: flex-start;
        margin: 10px 0;
        * {
          color: $primary-color;
          font-size: 15px;
        }
      }
    }
    &-listing {
      &-section {
        padding: 35px 15px;
        @include shdow;
        background-color: $white;
        margin-bottom: 35px;
        border-radius: $radius;
      }
      &-table {
        table {
          width: 100%;
        }
        td,
        th {
          padding: 15px;
          border-bottom: 1px solid $primary-color;
        }
      }
    }
  }
}

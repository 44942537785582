.search-ctn {
  @include my_flex_space;
  border: 1px solid #ccc;
  height: 35px;
  border-radius: $radius;
  overflow: hidden;
  //max-width: 450px !important;
  width: 100% !important;

  input[type="text"] {
    outline: none;
    border: none !important;
    background-color: $white !important;
    width: calc(100% - 50px);
    display: block;
    padding: 3px;
    height: 100%;

    &:focus {
      outline: none;
      border: none;
    }
  }
  span {
    @include my_flex_center;
    background-color: $primary-color;
    height: 100%;
    width: 50px;
    overflow: hidden;
    cursor: pointer;

    path {
      color: $white;
    }
  }
}

.certificate-container {
  padding: 25px;
  width: 100%;
  //max-width: 900px;

  .certificate {
    &-header {
      @include my_flex_space();
      padding: 25px;

      .logo-ctn {
        width: 200px;
        img {
          @include img_cover;
        }
      }
    }
    &-body {
      padding: 8px 0;
      @include my_flex_center();
      flex-direction: column;
      h1 {
        padding: 10px;
        color: $secondary;
        text-align: center;
        @include my_flex_center();
        font-family: $lato-bold;
        text-transform: uppercase;
        font-family: Georgia, "Times New Roman", Times, serif;
      }
    }
    &-content {
      @include my_flex_center();
      flex-direction: column;
      h2 {
        font-family: $lato-bold;
        color: $black;
        padding: 10px 0;
        text-align: center;
        @include my_flex_center();
        margin: 0 !important;
      }
      hr {
        text-align: center;
        width: 250px !important;
        @include my_flex_center();
        margin: 0 !important;
        margin-bottom: 10px !important;
      }
      div {
        font-size: 20px !important;
        line-height: 30px;
        text-align: center;
      }
    }
  }
}

.input-file-ctn {
  label {
    display: block;
    margin: 15px 0 5px 0;
    font-family: $lato-bold !important;
  }
  //[type="email"]

  .input {
    border: 1px solid #ccc;
    background-color: $white !important;
    width: 100%;
    height: 38px;
    border-radius: $radius;
    @include my_flex_center;
  }
  input {
    padding: 8px 20px;
    outline: none;
    border: 1px solid #ccc;
    background-color: $white !important;
    width: 100%;
    display: block;
    margin-bottom: 10px;
    border-radius: $radius;
    display: none;
    height: 100%;

    &:focus {
      outline: none;
    }
  }
}

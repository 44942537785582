.default-layout-container {
    width:100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    header{
        height: 90px;
    }

    main{
        //padding-top: 48px;
        min-height: calc(100vh - (90px + 235px));
    }

     footer{
        bottom: 0 !important;
        width: 100%;
        height: 100%;
    } 
}

@font-face {
  font-family: "Lato-Regular";
  src: url("../../fonts/Lato/Lato-Regular.ttf");
}

@font-face {
  font-family: "Lato-Bold";
  src: url("../../fonts/Lato/Lato-Bold.ttf");
}

@font-face {
  font-family: "Lato-Italic";
  src: url("../../fonts/Lato/Lato-Italic.ttf");
}

@font-face {
  font-family: "Lato-Light";
  src: url("../../fonts/Lato/Lato-Light.ttf");
}

@font-face {
  font-family: "Lato-Black";
  src: url("../../fonts/Lato/Lato-Black.ttf");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  position: relative;
  font-size: 16px;
  font-family: $lato-regular;
  // z-index: 5;
  line-height: 25px;
  color: $dark75; //$ash3;
}

body {
  overflow-x: hidden;
}

img {
  width: 100%;
  height: auto;
}

ul{
  margin: 0;
  padding: 0 !important;
}

h1 {
  font-size: 48px !important;
  line-height: 3.1rem !important;

  @media (max-width: 767px) {
    font-size: 2rem !important;
    line-height: 2.4rem !important;
  }
  @media (max-width: 600px) {
    font-size: 1.8rem !important;
    line-height: 2rem !important;
  }
}
h2 {
  font-size: 36px !important;
}
h3 {
  font-size: 32px !important;
}
h4 {
  font-size: 28px !important;
}
h5 {
  font-size: 24px !important;
}
h6 {
  font-size: 18px !important;
  line-height: 25px !important;
}

li {
  list-style-type: none;
}

.b-container {
  max-width: 1170px;
  margin: 0 auto !important;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
}

a {
  text-decoration: none !important;
  word-wrap: break-word;
}

.errors {
  color: red;
  margin: 5px 0;
  @include my_flex_center;
  font-family: $lato-bold;
  text-align: center;
  font-size: 12px;
}

.get-started-btn {
  color: $white;
  border-radius: $radius;
  background-color: $secondary-color;
  padding: 10px 15px;
  cursor: pointer;
  border: 1px solid $secondary-color;
  transition: 0.8s;

  &:hover {
    border: 1px solid $secondary-color;
    background-color: transparent;
    color: $white;
  }
}

.submit-btn {
  transition: 0.8s;
  cursor: pointer;
  button {
    padding: 10px 20px;
    width: 100%;
    background-color: $primary-color;
    border-radius: $radius;
    border: none;
    outline: none;
    transition: 0.8s;
    color: $white !important;
    white-space: nowrap;
  }

  /* path{
    color: $white;
  } */

  span {
    margin-right: 15px;
    color: $white;
    transition: 0.8s;
  }
  @media screen and (max-width: 1100px) {
    margin-bottom: 15px;
  }
}
.delete-btn {
  path {
    color: red;
  }
  box-shadow: 0px 4px 6px rgb(0 0 0 / 5%), 0px 2px 4px rgb(0 0 0 / 5%),
    0px 1px 2px rgb(0 0 0 / 10%);
  padding: 5px;
  margin: 5px;
  height: 25px;
  width: 25px;
  @include my_flex_center;
  border-radius: 100%;
  cursor: pointer;
}

.edit-btn {
  path {
    color: $primary2;
  }
  box-shadow: 0px 4px 6px rgb(0 0 0 / 5%), 0px 2px 4px rgb(0 0 0 / 5%),
    0px 1px 2px rgb(0 0 0 / 10%);
  padding: 5px;
  margin: 5px;
  height: 25px;
  width: 25px;
  @include my_flex_center;
  border-radius: 100%;
  cursor: pointer;
}
.trash-btn {
  path {
    color: red;
  }
  box-shadow: 0px 4px 6px rgb(0 0 0 / 5%), 0px 2px 4px rgb(0 0 0 / 5%),
    0px 1px 2px rgb(0 0 0 / 10%);
  padding: 5px;
  margin: 5px;
  height: 25px;
  width: 25px;
  @include my_flex_center;
  border-radius: 100%;
  cursor: pointer;
}

.add-btn {
  path {
    color: #016401;
  }
  box-shadow: 0px 4px 6px rgb(0 0 0 / 5%), 0px 2px 4px rgb(0 0 0 / 5%),
    0px 1px 2px rgb(0 0 0 / 10%);
  padding: 5px;
  margin: 5px;
  height: 25px;
  width: 25px;
  @include my_flex_center;
  border-radius: 100%;
  cursor: pointer;
}

.spc-btn {
  path {
    color: $other-color;
  }
  box-shadow: 0px 4px 6px rgb(0 0 0 / 5%), 0px 2px 4px rgb(0 0 0 / 5%),
    0px 1px 2px rgb(0 0 0 / 10%);
  padding: 5px;
  margin: 5px;
  height: 25px;
  width: 25px;
  @include my_flex_center;
  border-radius: 100%;
  cursor: pointer;
}
.restore-btn {
  path {
    color: $other-color;
  }
  box-shadow: 0px 4px 6px rgb(0 0 0 / 5%), 0px 2px 4px rgb(0 0 0 / 5%),
    0px 1px 2px rgb(0 0 0 / 10%);
  padding: 5px;
  margin: 5px;
  height: 25px;
  width: 25px;
  @include my_flex_center;
  border-radius: 100%;
  cursor: pointer;
}

.archive-btn {
  box-shadow: 0px 4px 6px rgb(0 0 0 / 5%), 0px 2px 4px rgb(0 0 0 / 5%),
    0px 1px 2px rgb(0 0 0 / 10%);
  padding: 5px;
  margin: 5px;
  height: 25px;
  width: 25px;
  path {
    color: $ash1;
  }
  @include my_flex_center;
  border-radius: 100%;
  cursor: pointer;
}
.show-btn {
  height: 25px;
  width: 25px;
  box-shadow: 0px 4px 6px rgb(0 0 0 / 5%), 0px 2px 4px rgb(0 0 0 / 5%),
    0px 1px 2px rgb(0 0 0 / 10%);
  padding: 5px;
  margin: 5px;
  path {
    color: $primary-color;
  }
  svg {
    /* height: 10px;
    width: 10px; */
  }
  @include my_flex_center;
  border-radius: 100%;
  //padding: 5px 10px;
  //background-color: $primary-color;
  cursor: pointer;
}

.link-ctn {
  //padding: 5px 0;
  margin-right: 5px;
  a {
    font-size: 12px !important;
    font-weight: normal !important;
    //font-family: $lato-light;
  }
}

.remove-icon {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #c24130;
  color: $white;
  @include my_flex_center;
  font-family: $lato-bold;
  margin-left: 10px;
  font-size: 10px !important;
  cursor: pointer;
}

.clamp-lines__button {
  background: none;
  border: 0;
  color: $primary-color;
  cursor: pointer;
  padding: 0;
}

.clamp-lines__button:hover {
  //border-bottom: 1px solid red;
}
.hcgwbo {
  font-family: $lato-bold;
}

.radio-ctn {
  @include my_flex_space;
  justify-content: flex-end;
  flex-direction: row-reverse;
  padding: 5px 0;
  margin-bottom: 10px;

  label {
    margin-left: 10px;
  }
}

.link-btn {
  border: none;
  background-color: $primary-color;
  color: $white;
  border-radius: $radius;
  padding: 3px 10px;
  @include my_flex_center;

  svg {
    margin: 0 5px;
  }

  path {
    color: $white;
  }

  &:hover {
    background-color: rgba($color: $primary-color, $alpha: 0.7);
  }
}

.dash-crumb {
  &-container {
    display: flex;
    align-items: center;
    padding: 5px 0;
  }
  &-link {
    color: $primary-color;
    padding: 0 5px;
    cursor: pointer;
    &-active {
      padding: 0 5px;
      color: $ash1;
    }
  }
  &-separator {
    color: $primary-color;
  }
}


.no-data-wraper {
  margin: 25px 0;
  padding: 25px 0;
}

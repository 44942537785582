.auth-layout-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    
    a{
        color: $primary-color;
    }
}

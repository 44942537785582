$primary-color: #FA9E26;
$primary2:#494244;
$primary3:#cae2fa;
$primary4:#0b5a9b;
$secondary-color: #Fff7ee /* 001B4e */;
$secondary: #ff4800 /* 001B4e */;
$other-color:#81c6ff;
$white: #fff;
$black: #000;
$dark75:#120035;
$other:#0f0900;
$ash3: #434343;
$ash1:#868B93;
$lg-gray:#F4F6FA;
$g-white: #F4F6FA; //#F2EFFB e5edfb;;
$lato-regular: "Lato-Regular", sans-serif;
$lato-light: "Lato-Light", sans-serif;
$lato-bold: "Lato-Bold", sans-serif;
$lato-italic: "Lato-Italic", sans-serif;
$lato-black: "Lato-Black", sans-serif;
$radius:5px;
$side-width:300px;
$mb:35px;

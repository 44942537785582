.input-ctn {
    label {
      display: block;
      margin: 15px 0 5px 0;
      font-family: $lato-bold !important;
    }
    input[type="email"],
    input[type="text"],
    input[type="tel"],
    input[type="number"],
    input[type="date"],
    input[type="time"],
    input[type="url"],
    input[type="datetime-local"],
    select,
    textarea {
      padding: 8px 20px;
      outline: none;
      border: 1px solid #ccc;
      background-color: $white !important;
      width: 100%;
      display: block;
      margin-bottom: 10px;
      border-radius: $radius;
  
      &:focus {
        outline: none;
      }
    }
  }
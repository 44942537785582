.default-footer-container {
  background-color: $primary-color;
  padding: 25px 0;

  .footer {
    &-wraper {
      @include my_flex_space;
      align-items: flex-start;
      //flex-wrap: wrap;
      width: 100%;
    }
    &-container {
      width: 25%;
      //padding: 25px;
      margin: 0 15px;
    }
    &-title {
      @include my_flex_center;
      justify-content: flex-start;
      height: 35px;
      color: $white;
      font-family: $lato-bold;
      font-size: 18px;
      margin-bottom: 5px;
      width: 100%;
      img {
        //@include img_contain;
        object-fit: contain;
        width: 100px;
        height: 100%;
      }
    }
    &-ctn {
      width: 100%;
      div {
        color: $white;
        font-family: $lato-light;
      }
      ul {
        padding: 0;
        margin: 0;
        

        li {
          text-align: left;
          color: $white;
          padding-bottom: 5px;
          display: flex;
          flex-wrap: nowrap;
          font-family: $lato-light;
          transition:  14s !important;

          path {
            color: $white;
          }

          span {
            color: $white;
            margin-left: 5px;
            font-family: $lato-light;
          }

          &:hover {
            color: $white;
            font-family: $lato-regular;

            span,
            path {
              color: $white;
              font-family: $lato-regular;
            }
          }
        }
      }
    }
  }

  .copy-right {
    @include my_flex_center;
    margin-top: 25px;

    div,
    strong,
    span {
      color: $white;
    }
  }
}

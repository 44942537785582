.default-header-container {
  max-height: 90px;
  background-color: $primary-color;
  color: $white;
  position: fixed;
  padding: 15px 0;
  width: 100%;
  height: 100%;
  z-index: 100 !important;
  transition: 0.8s;

  &.bgOnScroll {
    background-color: $white;
    color: $black;
    @include shdow();

    .default-header {
      .nav-active {
        color: $black;
      }

      ul {
        li {
          color: $black;
        }
      }

      .get-started-btn {
        color: $black !important;

        &:hover {
          color: $black !important;
        }
      }
    }
  }

  .default-header {
    @include my_flex_space;
    color: $white;

    .nav-active {
      color: $white;
      @keyframes letAnim {
        /*   to ou 100% c'est la mÃĒme chose en animation */
        100% {
          transform: translateX(-50%) scaleX(1);
        }
      }
      li {
        &::after {
          content: "";
          position: absolute;
          background: $secondary-color;
          width: 100%;
          height: 3px;
          bottom: -7px;
          left: 50%;
          transform: translateX(-50%) scaleX(0);
          animation: letAnim 0.5s ease-in-out forwards;
          transform-origin: left;
        }
      }
    }

    .get-started-btn{
      margin-left: 35px;
    }

    ul {
      @include my_flex_space;
      margin: 0;
      padding: 0;

      li {
        color: $white;
        font-family: $lato-bold;
        @include my_flex_center;
        margin: 0 15px;
      }
    }


    &-logo {
      @include my_flex_center;

      img {
        @include img_contain;
      }
    }
    &-wraper {
      width: 100%;
      height: 100%;
      color: $white;
      @include my_flex_space;
    }
  }
}

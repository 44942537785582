.select-ctn {
  label {
    display: block;
    margin: 15px 0 5px 0;
    font-family: $lato-bold !important;
  }

  select {
    padding: 8px 20px;
    outline: none;
    border: 1px solid #ccc;
    background-color: $white !important;
    width: 100%;
    display: block;
    margin-bottom: 20px;
    border-radius: $radius;

    &:focus {
      outline: none;
    }
  }
}

.password-ctn {
  .password {
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    border-radius: $radius;
    width: 100%;
    margin-bottom: 20px;

    input[type="password"],
    input[type="text"] {
      border: none;
      outline: none;
      padding: 8px 20px;
    }

    input[type="password"],
    input[type="text"] {
      width: calc(100% - 50px);
      margin: 0;
    }

    span {
      border-left: 1px solid #ccc;
      //padding: 9px 0;
      height:41px;
      @include my_flex_center;
      cursor: pointer;
      background: $primary-color;
      width: 50px;
      overflow: hidden;

      svg {
        font-size: 18px;
      }
      path {
        color: $white !important;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.dashboard-layout-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden !important;
  //overflow-y: scroll !important;

  a {
    color: $primary-color;
  }

  main {
    width: calc(100% - 300px);
    //overflow-x: hidden !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    height: 100vh;
    @media screen and (max-width: 1100px) {
      width: 100% !important;
    }
  }
}
